import { AfterViewInit, Component, ElementRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { gsap } from 'gsap';
import { Platform } from '@ionic/angular';
import { IJackpot } from 'bp-framework/dist/casino/casino.interface';

interface Letter {
  id: string;
  filename: string;
  src: string;
}
@Component({
  selector: 'app-jackpot-winner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './jackpot-winner.component.html',
  styleUrls: ['./jackpot-winner.component.scss']
})
export class JackpotWinnerComponent implements AfterViewInit, OnDestroy {
  private platform: Platform = inject(Platform);

  @ViewChild('rotatingImage') rotatingImageRef!: ElementRef;

  @Input() jackpotDetails!: Partial<IJackpot>;

  letters: Letter[] = [
    { id: 'j', filename: 'j.svg', src: '/images/jackpot/j.svg' },
    { id: 'a', filename: 'a.svg', src: '/images/jackpot/a.svg' },
    { id: 'c', filename: 'c.svg', src: '/images/jackpot/c.svg' },
    { id: 'k', filename: 'k.svg', src: '/images/jackpot/k.svg' },
    { id: 'p', filename: 'p.svg', src: '/images/jackpot/p.svg' },
    { id: 'o', filename: 'o.svg', src: '/images/jackpot/o.svg' },
    { id: 't', filename: 't.svg', src: '/images/jackpot/t.svg' }
  ];

  private backgroundImageNode!: any;
  private imageTimeline!: gsap.core.Timeline;
  private lettersTimeline!: gsap.core.Timeline;

  //#region lifecycle hooks
  ngAfterViewInit(): void {
    this.setupLetters();
  }

  ngOnDestroy(): void {
    // Kill the timeline
    if (this.imageTimeline) {
      this.imageTimeline.pause(0).kill();
    }

    if (this.lettersTimeline) {
      this.lettersTimeline.pause(0).kill();
    }

    // Optionally, kill all tweens and timelines associated with this component
    gsap.killTweensOf(this.rotatingImageRef.nativeElement);
    gsap.killTweensOf(this.letters.map(letter => document.getElementById(letter.id)));
    gsap.killTweensOf(this.backgroundImageNode);
  }
  //#endregion

  private setupLetters(): void {
    // Initialize GSAP timeline
    this.lettersTimeline = gsap.timeline();

    // Select all letter elements
    const letterElements = this.letters.map(letter => document.getElementById(letter.id));

    // Set initial properties
    gsap.set(letterElements, { scale: 0, opacity: 0 });

    this.lettersTimeline.to({}, { duration: 0.5 });

    // Add animation to the timeline with stagger
    this.lettersTimeline
      .to(letterElements, {
        duration: 0.5, // Adjust duration as needed
        scale: 2,
        opacity: 1,
        ease: 'power2.out', // Use bounce ease
        stagger: 0.1 // Stagger the animations
      })
      .to(letterElements, {
        duration: 0.5, // Adjust duration as needed
        scale: 1,
        ease: 'power2.out' // Use bounce ease
      });

    // Create a timeline for each letter
    letterElements.forEach((letterElement: any, index: number) => {
      const letterTimeline = gsap.timeline({ repeat: -1, yoyo: true });

      // Add glow effect
      letterTimeline.to(letterElement, {
        duration: 1,
        filter: 'drop-shadow(0 0 10px #ffd576)',
        ease: 'power1.inOut',
        onStart: () => {
          this.checkIfBackgroundImageShouldStartAnimating(index);
        }
      });

      // Add position animation
      letterTimeline
        .to(letterElement, {
          duration: 1,
          y: '-=10',
          ease: 'power1.inOut'
        })
        .to(letterElement, {
          duration: 1,
          y: '+=10',
          ease: 'power1.inOut'
        });

      // Add scale animation
      letterTimeline.to(letterElement, {
        duration: 1,
        scale: 1.2,
        ease: 'power1.inOut'
      });
    });
  }

  private checkIfBackgroundImageShouldStartAnimating(index: number): void {
    if (!this.backgroundImageNode && index === this.letters.length - 1) {
      // Start the background image animation
      this.setupImage();
    }
  }

  private setupImage(): void {
    // Get the height and width of the document
    const documentHeight = this.platform.height();
    const documentWidth = this.platform.width();

    // Determine the larger dimension
    const largerDimension = Math.max(documentHeight, documentWidth);

    // Set the width of the image to match the larger dimension
    this.rotatingImageRef.nativeElement.style.width = largerDimension * 1.2 + 'px';
    this.rotatingImageRef.nativeElement.style.height = 'auto'; // Ensures that the aspect ratio of the image is maintained

    //
    // Get the image element by its ID
    this.backgroundImageNode = document.getElementById('rotating-background');

    // Create a GSAP timeline
    this.imageTimeline = gsap.timeline({ repeat: -1 });

    // Wait for 2 seconds
    this.imageTimeline.to({}, { duration: 1.5 });

    // First, animate opacity from 0 to 1
    this.imageTimeline.to(this.backgroundImageNode, {
      opacity: 0.5,
      duration: 1,
      ease: 'power2.inOut'
    });

    // Then, rotate the image indefinitely
    this.imageTimeline.to(this.backgroundImageNode, { rotation: 360, duration: 60, ease: 'none', repeat: -1 }, '-=1'); // '-=1' makes the rotation start 1 second after the opacity animation starts
  }
}
