import { ICasinoGameDetails } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';

export const filterGamesByTitle = <T, C>(games: Partial<ICasinoGameDetails<T, C>>[], keyword: string): Partial<ICasinoGameDetails<T, C>>[] => {
  if (!keyword) {
    return games;
  }
  return games.filter(game => game?.title?.toLowerCase().includes(keyword.toLowerCase()));
};

export const handleCategoryChange = (games: Partial<ICasinoGameDetails<any, any>>[], category: number | undefined): Partial<ICasinoGameDetails<any, any>>[] => {
  return games.filter(game => game?.categories?.includes(category));
};

export const filterGamesByType = (games: Partial<ICasinoGameDetails<any, any>>[], type: string): Partial<ICasinoGameDetails<any, any>>[] => {
  return games.filter(game => game?.type === type);
};

export const transformVendorsToItemList = (games: Partial<ICasinoGameDetails<any, any>>[]): Partial<IListItem<string>>[] => {
  const uniqueVendors = Array.from(new Set(games.map(game => game.vendor)));

  return uniqueVendors.map(vendor => ({
    label: typeof vendor === 'string' ? vendor?.charAt(0).toUpperCase() + vendor?.slice(1) : '',
    labelT9nKey: '',
    value: vendor,
    selected: true
  }));
};

export const transformTypesToItemList = (games: Partial<ICasinoGameDetails<any, any>>[]): Partial<IListItem<string>>[] => {
  const uniqueTypes = Array.from(new Set(games.map(game => game.type)));

  return uniqueTypes.map(type => ({
    label: type,
    labelT9nKey: '',
    value: type
  }));
};

export const filterGamesByParams = (
  allGames: Partial<ICasinoGameDetails<any, any>>[],
  categoryId: number | undefined,
  providers: string[],
  type: string,
  keyword: string
): Partial<ICasinoGameDetails<any, any>>[] => {
  // If providers array is empty, return an empty array
  if (providers.length === 0) {
    return [];
  }

  return allGames.filter(
    game =>
      (categoryId ? game.categories?.includes(categoryId) : true) &&
      providers.includes(game.vendor || '') &&
      (type ? game.type === type : true) &&
      (keyword ? game.title?.toLowerCase().includes(keyword.toLowerCase()) : true)
  );
};
