import { Injectable, inject } from '@angular/core';
import { AuthService, BpCasinoApiService, BpCoreApiService, PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';
import { extractUserDetailsFromToken, mergeUserDetailsWithProfileData } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IPlayer, ITokenPayload, IUser } from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ICasinoGameDetails, ICasinoGameLaunchDetails, IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { CasinoGame, CasinoLaunchDetails, CasinoPayload, IJackpotItem } from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames, mapCasinoJackpots } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';
import { TranslateService } from '@ngx-translate/core';
import { Routes } from '@angular/router';
import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'bp-framework/dist/configuration/configuration.interface';
import { BETPLATFORM_PROD_JUST_CASINO_ROUTES, BETPLATFORM_PROD_JUST_CASINO_SIDEMENU } from './routes/routes.prod.justcasino';
import { BETPLATFORM_DEV_JUST_CASINO_ROUTES, BETPLATFORM_DEV_JUST_CASINO_SIDEMENU } from './routes/routes.dev.justcasino';
import { BETPLATFORM_DEV_SURFPOINT_ROUTES } from './routes/routes.dev.surfpoint';
import { BETPLATFORM_DEV_DEFAULT_ROUTES, BETPLATFORM_DEV_DEFAULT_SIDEMENU } from './routes/routes.dev.default';
import { INavGroup, INavigationItem } from 'bp-framework/dist/common/common.interface';

@Injectable({
  providedIn: 'root'
})
export class AdapterBetPlatformService {
  private bpCoreApiService: BpCoreApiService = inject(BpCoreApiService);
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private authService: AuthService = inject(AuthService);
  private translateService: TranslateService = inject(TranslateService);
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  public buildBetPlatformRoutes(): Routes {
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_PROD_JUST_CASINO_ROUTES;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_DEV_JUST_CASINO_ROUTES;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return BETPLATFORM_DEV_SURFPOINT_ROUTES;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return BETPLATFORM_DEV_DEFAULT_ROUTES;
    } else {
      return [];
    }
  }

  public buildBetPlatformSidemenuRoutes(): Partial<INavGroup>[] {
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_PROD_JUST_CASINO_SIDEMENU;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_DEV_JUST_CASINO_SIDEMENU;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return BETPLATFORM_DEV_DEFAULT_SIDEMENU;
    } else {
      return [];
    }
  }

  public buildBetPlatformMainMenuRoutes(): Partial<INavigationItem<number>>[] {
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return [];
    } else {
      return [];
    }
  }

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const loginPayload: ITokenPayload | null = await this.bpCoreApiService.authenticateWithUsernameAndPassword(username, password);

        if (!loginPayload?.access_token) {
          return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentials')));
        }

        const transformedLoginData: Partial<IUserDetails> | null = extractUserDetailsFromToken(loginPayload);

        await this.authService.userAuthChanged(transformedLoginData);

        const getProfilePayload: IPlayer | null = await this.bpCoreApiService.getPlayerProfile();

        if (!getProfilePayload) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
        }

        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = mergeUserDetailsWithProfileData(currentDetails, getProfilePayload);

        await this.authService.userAuthChanged(mergedValue);

        resolve(mergedValue);
      } catch (error) {
        // TODO: Check if we can present error message instead of presenting custom message without any context.
        // TODO: Revisit error handling and error messages in the entire environment adapter
        return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentialsOrTryLater')));
      }
    });
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const refreshToken: string | undefined = await this.authService.user$.value?.auth?.refreshToken;

        if (!refreshToken) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveRefreshToken')));
        }

        const loginPayload: ITokenPayload | null = await this.bpCoreApiService.refreshToken(refreshToken);

        if (!loginPayload?.access_token) {
          return reject(new Error(this.translateService.instant('notifications.failedToRefreshToken')));
        }

        await this.authService.userAuthChanged(extractUserDetailsFromToken(loginPayload));

        const getProfilePayload: IPlayer | null = await this.bpCoreApiService.getPlayerProfile();

        if (!getProfilePayload) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
        }

        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = mergeUserDetailsWithProfileData(currentDetails, getProfilePayload);

        await this.authService.userAuthChanged(mergedValue);

        resolve(mergedValue);
      } catch (error: unknown) {
        return reject(error);
      }
    });
  }

  // TODO: Revisit naming of this method. It should be more descriptive. Everyone are 'user' but we also have 'player' and 'profile' in the same context
  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IPlayer | null = await this.bpCoreApiService.getPlayerProfile();
        resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile) : null);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async patchUserLanguage(lang: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IUser | null = await this.bpCoreApiService.patchUserLanguage({
          language: lang
        });

        resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile) : null);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async getAllCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.bpCasinoApiService.getAllCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoLaunchDetails | null = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.launchUrl, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  // TODO: Remove this method and replace it with getDetailsToLaunchGame or vice versa
  // TODO: Check if there are some other api calls that are not used and remove them
  public async getSingleCasinoGameToPlay(gameId: number): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve(response);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const jackpots: IJackpotItem[] | null = await this.bpCasinoApiService.getJackpotsListForPlayers();

        if (!jackpots) {
          return resolve(null);
        }

        resolve(mapCasinoJackpots(jackpots));
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }
}
