import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IonicModule, MenuController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoService } from '../../casino.service';

import { filterProviderLabelsByKeyword, updateProviderSelections } from './casino-providers.utils';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-casino-providers',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './casino-providers.component.html',
  styleUrls: ['./casino-providers.component.scss']
})
export class CasinoProvidersComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoService: CasinoService = inject(CasinoService);
  private menuController: MenuController = inject(MenuController);

  @Input() showSearch = true;
  @Input() collapsible = false;
  @Input() isCollapsed = false;

  @HostBinding('class.collapsible') get isCollapsible() {
    return this.collapsible;
  }

  @HostBinding('class.is-collapsed') get uiIsCollapsed() {
    return this.isCollapsed;
  }

  public presentedProviders: Partial<IListItem<string>>[] = [];
  public changesOnProviders$: Subject<Partial<IListItem<string>>[]> = new Subject<Partial<IListItem<string>>[]>();

  private mobileSearchKeyword = '';

  ngOnInit(): void {
    this.casinoService.providers$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((providers: Partial<IListItem<string>>[]) => {
      this.presentedProviders = filterProviderLabelsByKeyword(this.mobileSearchKeyword, this.casinoService.providers);
    });

    this.changesOnProviders$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300)).subscribe((providersWithUpdatedSelectedState: Partial<IListItem<string>>[]) => {
      const updatedProviders: Partial<IListItem<string>>[] = updateProviderSelections(providersWithUpdatedSelectedState, this.casinoService.providers);
      this.casinoService.handleSelectedProvidersChange(updatedProviders);
    });
  }

  public handleProviderSelection(provider: Partial<IListItem<string>>): void {
    if (this.casinoService.singleSelectedProvider) {
      this.casinoService.singleSelectedProvider.selected = false;
    }

    provider.selected = !provider.selected;

    this.casinoService.singleSelectedProvider = provider?.selected ? provider : null;
    const tmpListOfSelectedProviders: Partial<IListItem<string>>[] = this.casinoService.singleSelectedProvider ? [this.casinoService.singleSelectedProvider] : [];
    this.changesOnProviders$.next(tmpListOfSelectedProviders);
  }

  public handleArrowBtnClicked(): void {
    if (!this.collapsible) {
      this.menuController.close();
      return;
    }

    if (this.collapsible) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  public handleKeywordChange(event: Event): void {
    if (!this.showSearch) {
      return;
    }

    this.mobileSearchKeyword = (event as CustomEvent)?.detail?.value;
    this.presentedProviders = filterProviderLabelsByKeyword(this.mobileSearchKeyword, this.casinoService.providers);
  }
}
