import { inject, Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { CasinoGamePlayMode, ICasinoGameDetails, ICasinoGameLaunchDetails, ICasinoGamesSearchParams, IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { CASINO_CATEGORIES_BETPLATFORM } from 'bp-framework/dist/env-specific/betplatform/casino/casino.const';
import {
  CasinoGame,
  CasinoLaunchDetails,
  ICasinoGamesSearchParams as IBPCasinoGamesSearchParams,
  IJackpotItem
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames, mapCasinoJackpots } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

import { BpCasinoApiService } from 'bp-angular-library';

import { CasinoAbstractService, UserAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { JackpotWinnerComponent } from '../../../../shared/components/jackpot-winner/jackpot-winner.component';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoBetplatformService extends CasinoAbstractService {
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private translateService: TranslateService = inject(TranslateService);
  private authService: AuthenticationService = inject(AuthenticationService);

  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private modalController: ModalController = inject(ModalController);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  constructor() {
    super();
    this.getJackpotsList();
  }

  public async getCasinoGames(params: ICasinoGamesSearchParams): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const tmpParams: IBPCasinoGamesSearchParams = {
          offset: params?.offset,
          limit: params?.limit,
          query: params?.query,
          tag_title: params?.tagTitle,
          tag_type: params?.tagType,
          vendor: params?.vendor
        };
        const response: any | null = await this.bpCasinoApiService.getAllCasinoGames(tmpParams);
        // TODO: It happens that 'prod' and 'stage' environments have different data structure for API responses. Check if this is the case here and adjust the code accordingly
        const tmpFixList: any[] = response?.results ? response.results : response?.data ? response.data : [];
        const mapped: ICasinoGameDetails<any, any>[] = tmpFixList && Array.isArray(tmpFixList) ? mapCasinoGames(tmpFixList) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    return Promise.resolve(CASINO_CATEGORIES_BETPLATFORM);
  }

  public async getDetailsToLaunchGame(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoLaunchDetails> | null = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId, mode);
        resolve({ url: response?.data?.launchUrl, token: response?.data?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  // TODO: Remove this method and replace it with getDetailsToLaunchGame or vice versa
  // TODO: Check if there are some other api calls that are not used and remove them
  public async getSingleCasinoGameToPlay(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId, mode);
        resolve(response);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const jackpots: IBpPayload<IJackpotItem[]> | null = await this.bpCasinoApiService.getJackpotsListForPlayers();

        if (!jackpots?.data) {
          return resolve(null);
        }

        resolve(mapCasinoJackpots(jackpots?.data));
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  /////// JACKPOTS SERVICE

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    if (playerId === this.authService.user$.value?.id) {
      this.presentJackpotWinnerModal(jackpot);
      this.userAbstractService.updateUserWithProfileData();
    }
  }

  public async getJackpotsList(): Promise<void> {
    const tmpValue: IJackpot[] | null = await this.getJackpotsListForPlayers();
    this.jackpots$.next(tmpValue);
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    const tmpJackpots: IJackpot[] = this.jackpots$.value || [];
    const index: number = tmpJackpots.findIndex(item => item.id === jackpot.id);

    if (index !== -1) {
      tmpJackpots[index] = { ...tmpJackpots[index], ...jackpot };
      this.jackpots$.next(tmpJackpots);
    }
  }

  private async presentJackpotWinnerModal(jackpotDetails: IJackpot): Promise<void> {
    const modal = await this.modalController.create({
      component: JackpotWinnerComponent,
      componentProps: {
        jackpotDetails
      },
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 10000);

    // const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   // console.log('SoftKeyboardComponent closed and confirmed');
    // }
  }

  // FAVORITES
  public async getFavoriteGames(): Promise<Partial<ICasinoGameDetails<any, any>>[]> {
    return new Promise<Partial<ICasinoGameDetails<any, any>>[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoGame> | null = await this.bpCasinoApiService.getFavoriteGames();
        const mapped: Partial<ICasinoGameDetails<any, any>>[] = response?.data && Array.isArray(response?.data) ? mapCasinoGames(response.data) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames')));
      }
    });
  }

  public async getIdsOfFavoriteGames(): Promise<number[]> {
    return new Promise<number[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<number[]> | null = await this.bpCasinoApiService.getIdsOfFavoriteGames();
        resolve(response?.data || []);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames')));
      }
    });
  }

  public async addGameToFavorites(gameId: number): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.bpCasinoApiService.addGameToFavorites(gameId);
        resolve();
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToAddGameToFavorites')));
      }
    });
  }

  public async removeGameFromFavorites(gameId: number): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.bpCasinoApiService.removeGameFromFavorites(gameId);
        resolve();
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRemoveGameFromFavorites')));
      }
    });
  }
}
