export const MAIN_ROUTE_PATHS = {
  notFound: 'not-found',
  login: 'login',
  home: 'home',
  landing: 'landing',
  betting: 'betting',
  bettingLive: 'betting-live',
  casino: 'casino',
  casinoLive: 'casino-live',
  greekKeno: 'greek-keno',
  virtualSports: 'virtual-sports',
  virtualGames: 'virtual-games'
};

export const PLAYER_ROUTE_ROOT_SEGMENT = 'player';

export const PLAYER_ROUTE_CHILD_SEGMENTS = {
  dashboard: 'dashboard',
  betSlips: 'bet-slips',
  transactions: 'transactions',
  details: 'details'
};
