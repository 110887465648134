<ion-header>
  <ion-toolbar>
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="handleClose()">{{ 'commons.close' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<!-- Login View -->
<ng-container *ngIf="currentView === 'login'">
  <ion-content>
    <ion-grid fixed="true">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col>
          <ion-card class="login-card">
            <ion-card-header>
              <ion-card-title>{{ 'pages.login.content.welcome' | translate }}</ion-card-title>
              <ion-card-subtitle>{{ 'pages.login.content.login' | translate }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <lib-login-section (handleFormSubmit)="handleLoginFormSubmit($event)">
                <ng-container submitButtonContent>{{ 'commons.logIn' | translate }}</ng-container>
              </lib-login-section>

              <!-- <div class="has-bottom-buttons">
                <ion-button fill="clear" (click)="toggleView('password-reset')">Forgot Password?</ion-button>
                <hr />
                <p>Don't have an account?</p>
                <ion-button fill="clear" (click)="toggleView('signup')">Sign Up</ion-button>
              </div> -->
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
<!-- Sign Up View -->
<ng-container *ngIf="currentView === 'signup'">
  <ion-content>
    <ion-grid fixed="true">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col>
          <ion-card class="login-card">
            <ion-card-header>
              <ion-card-title>{{ 'commons.logOut' | translate }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <lib-signup-section [customSuccessFunc]="handleSignUpSuccess">
                <ng-container submitButtonContent>{{ 'commons.signUp' | translate }}</ng-container>
              </lib-signup-section>

              <div class="has-bottom-buttons">
                <p>Already have an account?</p>
                <ion-button fill="clear" (click)="toggleView('login')">Go to Login</ion-button>
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
<!-- Password Reset -->
<ng-container *ngIf="currentView === 'password-reset'">
  <ion-content>
    <ion-grid fixed="true">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col>
          <ion-card class="login-card">
            <ion-card-header>
              <ion-card-title>Forgot password?</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <lib-password-reset-section [customSuccessFunc]="handlePasswordResetSuccess">
                <ng-container submitButtonContent>Reset Password</ng-container>
              </lib-password-reset-section>

              <div class="has-bottom-buttons">
                <ion-button fill="clear" (click)="toggleView('login')">Go to Login</ion-button>
                <hr />
                <p>Don't have an account?</p>
                <ion-button fill="clear" (click)="toggleView('signup')">Sign Up</ion-button>
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
<!-- Sign In Or Register to Play-->
<ng-container *ngIf="currentView === 'auth-to-play'">
  <ion-content>
    <ion-grid fixed="true">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col>
          <ion-card class="login-card">
            <ion-card-header>
              <ion-card-title>Uloguj se ili registruj i kreni da igras?</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-button (click)="toggleView('login')">Go to Login</ion-button>
              <ion-button (click)="toggleView('signup')">Sign Up</ion-button>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
