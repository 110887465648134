import { Injectable, inject } from '@angular/core';
import { AuthService, CasinoApiService, CoreApiService, SurfpointApiService } from 'bp-angular-library';
import { CasinoCategory, CasinoGame, CasinoGamePlay, CasinoGameTypes, CasinoPayload, ICasinoJackpot } from 'bp-framework/dist/env-specific/1x2team/casino/casino.interface';
import { ICasinoGameDetails, ICasinoGameLaunchDetails, IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { ILoginPayload, IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import { transformLoginDataToUserDetails, transformProfileDataToUserDetails } from 'bp-framework/dist/env-specific/1x2team/user/user.mappers';
import { mapCasinoCategories, mapCasinoGames, mapCasinoJackpots, mapSurfpointCasinoGames } from 'bp-framework/dist/env-specific/1x2team/casino/casino.mappers';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { CasinoCategories } from 'bp-framework/dist/env-specific/1x2team/casino/casino.enum';
import { IPayloadResults, ICasinoGameDetails as SurfpointGameDetails } from 'bp-framework/dist/env-specific/1x2team/casino/surfpoint.interface';

@Injectable({
  providedIn: 'root'
})
export class Adapter1x2teamService {
  private coreApiService: CoreApiService = inject(CoreApiService);
  private casinoApiService: CasinoApiService = inject(CasinoApiService);
  private authService: AuthService = inject(AuthService);
  private surfpointApiService: SurfpointApiService = inject(SurfpointApiService);

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const authResponse: Partial<ILoginPayload> | null = await this.coreApiService.authenticatePlayerWithUsernameAndPassword(username, password);

        if (!authResponse?.token) {
          return reject(new Error('Failed to login! Check your credentials'));
        }

        await this.authService.userAuthChanged(transformLoginDataToUserDetails(authResponse, {}));

        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();

        if (!userProfile?.id) {
          return reject(new Error('Failed to retreive user details!'));
        }

        const user: Partial<IUserDetails> | null = await this.authService.userAuthChanged(transformLoginDataToUserDetails(authResponse, userProfile));
        resolve(user);
      } catch (error) {
        return reject(new Error('Failed to login. Please check your username or password or try again later!'));
      }
    });
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();
        resolve(userProfile ? transformProfileDataToUserDetails(userProfile) : null);
      } catch (error) {
        return reject(new Error('Failed retrieve of user profile data'));
      }
    });
  }

  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    return new Promise<Partial<IListItem<number>>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoCategory> | null = await this.casinoApiService.getCasinoCategories();
        const mapped: Partial<IListItem<number>>[] = response?.results && Array.isArray(response?.results) ? mapCasinoCategories(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino categories'));
      }
    });
  }

  public async getAllCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.casinoApiService.getAllCasinoGames();
        const mapped: ICasinoGameDetails<CasinoGameTypes, CasinoCategories>[] = response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getAllCasinoGamesForSurfpoint(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: IPayloadResults<SurfpointGameDetails> | null = await this.surfpointApiService.getAvailableCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapSurfpointCasinoGames(response.results as any) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoGamePlay | null = await this.casinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.url, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error('Failed retrieve the game launch url'));
      }
    });
  }

  public async getJackpots(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const user: Partial<IUserDetails> | null = this.authService.user$.value;
        const jackpot: ICasinoJackpot | null = await this.casinoApiService.getJackpot(user?.account?.agent || '');

        if (!jackpot) {
          return resolve(null);
        }

        resolve(mapCasinoJackpots([jackpot]));
      } catch (error) {
        return reject(new Error('Failed retrieve the list of jackpots'));
      }
    });
  }
}
