<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="jackpot-container">
  <div class="letter-wrapper">
    <div class="winner-text gold-animated">WINNER</div>
    <div class="amount-wrapper">
      <span *ngIf="jackpotDetails!.value" class="amount-text gold-animated">{{ jackpotDetails.value | number: '1.2-2' }}</span>
    </div>
    <ng-container *ngFor="let letter of letters">
      <img [attr.id]="letter.id" class="letter" [attr.src]="letter.src" loading="lazy" />
    </ng-container>
  </div>
</div>
<img #rotatingImage id="rotating-background" src="images/jackpot/rotating-background.svg" alt="" />
