import { AppComponent } from './app/app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicModule } from '@ionic/angular';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthenticationInterceptor } from './app/core/interceptors/auth/authentication.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { EnvAdapterService } from './app/core/env-specific/env-adapter.service';
import { StorageService, AuthService, I18nService, PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';
import { Router } from '@angular/router';
import { appInitFactory } from './app/core/initializer/initializers';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ErrorHandlerService } from './app/core/error-handling/error-handler.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      IonicModule.forRoot({
        mode: 'md',
        rippleEffect: false,
        swipeBackEnabled: false
      }),
      IonicStorageModule.forRoot({
        name: '__appdb',
        driverOrder: [Drivers.LocalStorage]
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [Router, StorageService, AuthService, I18nService, EnvAdapterService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    { provide: PROJECT_ENV_CONFIG_TOKEN, useValue: environment },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    provideAnimations()
  ]
}).catch(err => console.error(err));
