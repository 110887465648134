import { Injectable, inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthService, PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';
import { ALL_ROUTES_FOR_1X2TEAM, MAIN_HEADER_LINKS_FOR_1X2TEAM, SIDEMENU_NAV_GROUPS_FOR_1X2TEAM } from './1x2team/routes.1x2team';
import { IListItem, INavGroup, INavigationItem } from 'bp-framework/dist/common/common.interface';
import { Adapter1x2teamService } from './1x2team/adapter-1x2team.service';
import { AdapterBetPlatformService } from './betplatform/adapter-betplatform.service';
import { ICasinoGameDetails, ICasinoGameLaunchDetails } from 'bp-framework/dist/casino/casino.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ILoginCredentials } from 'bp-framework/dist/auth/auth.interface';
import { STORAGE_KEYS } from 'bp-framework/dist/storage/storage.const';
import { CASINO_CATEGORIES_BETPLATFORM } from 'bp-framework/dist/env-specific/betplatform/casino/casino.const';
import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'bp-framework/dist/configuration/configuration.interface';
import { BETPLATFORM_DEV_SURFPOINT_ROUTES } from './betplatform/routes/routes.dev.surfpoint';

@Injectable({
  providedIn: 'root'
})
export class EnvAdapterService {
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);
  private adapter1x2teamService: Adapter1x2teamService = inject(Adapter1x2teamService);
  private adapterBetPlatformService: AdapterBetPlatformService = inject(AdapterBetPlatformService);
  private authService: AuthService = inject(AuthService);
  private storageService: StorageService = inject(StorageService);

  public buildAllRoutes(): Routes {
    if (this.projectConfig?.api?.provider === '1x2team') {
      if (this.projectConfig?.features?.frontendMode === 'surfpoint') {
        // TODO: Implement adapter instead of using BetPlatform routes
        return BETPLATFORM_DEV_SURFPOINT_ROUTES;
      } else {
        return ALL_ROUTES_FOR_1X2TEAM;
      }
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.buildBetPlatformRoutes();
    } else {
      return [];
    }
  }

  public buildSidemenuRoutes(): Partial<INavGroup>[] {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return SIDEMENU_NAV_GROUPS_FOR_1X2TEAM;
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.buildBetPlatformSidemenuRoutes();
    } else {
      return [];
    }
  }

  public buildMainMenuRoutes(): Partial<INavigationItem<number>>[] {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return MAIN_HEADER_LINKS_FOR_1X2TEAM;
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.buildBetPlatformMainMenuRoutes();
    } else {
      return [];
    }
  }

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.features?.storeCredentials === true && username && password) {
      const loginCredentials: ILoginCredentials = {
        username,
        password
      };

      await this.storageService.setLocalItem(STORAGE_KEYS.userCredentials, loginCredentials);
    }

    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.loginWithUsernameAndPassword(username, password);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.loginWithUsernameAndPassword(username, password);
    } else {
      return Promise.resolve(null);
    }
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.refreshToken();
    } else {
      return Promise.resolve(null);
    }
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getUserProfile();
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getUserProfile();
    } else {
      return Promise.resolve(null);
    }
  }

  public async patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.patchUserLanguage(langAlpha2Code);
    } else {
      return Promise.resolve(null);
    }
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    const freshData: Partial<IUserDetails> | null = await this.getUserProfile();
    await this.authService.userDetailsChanged(freshData);
    return this.authService.user$.value;
  }

  public async getCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      if (this.projectConfig?.features?.frontendMode === 'surfpoint') {
        return this.adapter1x2teamService.getAllCasinoGamesForSurfpoint();
      } else {
        return this.adapter1x2teamService.getAllCasinoGames();
      }
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getAllCasinoGames();
    } else {
      return Promise.resolve([]);
    }
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getDetailsToLaunchGame(gameId);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getDetailsToLaunchGame(gameId);
    } else {
      return Promise.resolve({} as any);
    }
  }

  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getCasinoCategories();
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return Promise.resolve(CASINO_CATEGORIES_BETPLATFORM);
    } else {
      return Promise.resolve([]);
    }
  }

  public async getJackpotsListForPlayers(): Promise<any> {
    // TODO: Implement this method and check how to handle the 1x2Team fetching for the jackpot. On BetPlatform, we might have list of jackpots, while on the 1x2team, we have only one. We should have some kind of adapter.
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve([]);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getJackpotsListForPlayers();
    } else {
      return Promise.resolve([]);
    }
  }
}
