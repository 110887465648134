import { Routes } from '@angular/router';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { MAIN_ROUTE_PATHS, PLAYER_ROUTE_CHILD_SEGMENTS, PLAYER_ROUTE_ROOT_SEGMENT } from 'src/app/shared/models/routing/routing.const';

export const BETPLATFORM_DEV_DEFAULT_ROUTES: Routes = [
  //#region Main
  {
    path: MAIN_ROUTE_PATHS.login,
    loadComponent: () => import('../../../../pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.landing,
    loadComponent: () => import('../../../../pages/landing/landing.component').then(m => m.LandingComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.casino,
    loadChildren: () => import('../../../../pages-casino/casino.routes').then(m => m.CASINO_ROUTES)
  },
  {
    path: MAIN_ROUTE_PATHS.greekKeno,
    loadComponent: () => import('../../../../pages-keno/keno/keno.component').then(m => m.kenoComponent)
  },
  //#endregion Main
  //#region Player
  {
    path: `${PLAYER_ROUTE_ROOT_SEGMENT}`,
    loadComponent: () => import('../../../../pages-player/player.component').then(m => m.PlayerComponent),
    children: [
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.dashboard,
        loadComponent: () => import('../../../../pages-player/dashboard/dashboard.component').then(m => m.DashboardComponent)
      },
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.betSlips,
        loadComponent: () => import('../../../../pages-player/bet-slips/bet-slips.component').then(m => m.BetSlipsComponent)
      },
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.transactions,
        loadComponent: () => import('../../../../pages-player/transactions/transactions.component').then(m => m.TransactionsComponent)
      },
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.details,
        loadComponent: () => import('../../../../pages-player/details/details.component').then(m => m.DetailsComponent)
      },
      {
        path: '',
        redirectTo: PLAYER_ROUTE_CHILD_SEGMENTS.dashboard,
        pathMatch: 'full'
      }
    ]
  },
  //#endregion Player
  {
    path: MAIN_ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: 'theme',
    loadComponent: () => import('../../../../pages/theme-testing/theme-testing.component').then(m => m.ThemeTestingComponent)
  },
  {
    path: '',
    redirectTo: MAIN_ROUTE_PATHS.casino, // Default page
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: MAIN_ROUTE_PATHS.notFound
  }
];

export const BETPLATFORM_DEV_DEFAULT_SIDEMENU: Partial<INavGroup>[] = [
  {
    path: MAIN_ROUTE_PATHS.casino,
    label: 'Casino',
    t9nKey: '',
    sortOrder: 3,
    icon: 'ph-club'
  },
  {
    path: MAIN_ROUTE_PATHS.greekKeno,
    label: 'Grčki kino',
    t9nKey: '',
    sortOrder: 4,
    icon: 'ph-fire-simple'
  }
];
