import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, ModalController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { DialogsService, LoginSectionComponent, PasswordResetSectionComponent, SignupSectionComponent } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/env-specific/env-abstracts';

@Component({
  selector: 'app-user-auth-actions-dialog',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, LoginSectionComponent, SignupSectionComponent, PasswordResetSectionComponent],
  templateUrl: './user-auth-actions-dialog.component.html',
  styleUrls: ['./user-auth-actions-dialog.component.scss']
})
export class UserAuthActionsDialogComponent implements OnInit {
  private modalController: ModalController = inject(ModalController);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private dialogsService: DialogsService = inject(DialogsService);

  @Input() currentView: 'login' | 'signup' | 'password-reset' | 'auth-to-play' = 'login';

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initializeForm();
  }
  //#endregion

  //#region methods
  private initializeForm(): void {
    //empty
  }

  public async handleLoginFormSubmit(event: { username: string; password: string }): Promise<void> {
    await this.dialogsService.presentLoading();

    this.userAbstractService
      .loginWithUsernameAndPassword(event.username, event.password)
      .then((user: Partial<IUserDetails> | null) => this.handleLoginSuccess(user))
      .catch((error: Error) => {
        this.dialogsService.presentToast({ message: error?.message, color: 'danger' });
      })
      .finally(() => {
        this.dialogsService.dismissLoading();
      });
  }

  public handleClose(): void {
    this.modalController.dismiss('hello theres', DIALOG_DISMISS_ROLES.cancel);
  }

  public handleLoginSuccess = (user: Partial<IUserDetails> | null): void => {
    this.modalController.dismiss(user, DIALOG_DISMISS_ROLES.confirm);
    // TODO: Check if this is really neaded. The initial reason for this was the need to reload the page after login on the 1x2team provider
    window.location.reload();
  };

  public handleSignUpSuccess = (): void => {
    this.modalController.dismiss('sign up success', DIALOG_DISMISS_ROLES.cancel);
  };

  public handlePasswordResetSuccess = (): void => {
    this.modalController.dismiss('password reset success', DIALOG_DISMISS_ROLES.cancel);
  };

  public toggleView(nextView: 'login' | 'signup' | 'password-reset'): void {
    this.currentView = nextView;
  }
  //#endregion
}
