import { inject, Injectable } from '@angular/core';
import { ICasinoGameDetails } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { EnvAdapterService } from 'src/app/core/env-specific/env-adapter.service';
import { filterGamesByParams, handleCategoryChange, transformTypesToItemList, transformVendorsToItemList } from './casino.utils';
import { BehaviorSubject, combineLatest, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  private envAdapterService: EnvAdapterService = inject(EnvAdapterService);

  private allCasinoGames: Partial<ICasinoGameDetails<any, any>>[] = [];
  public filteredCasinoGames$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]>([]);

  public categories: Partial<IListItem<number>>[] = [];
  public selectedCategory$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  public providers: Partial<IListItem<string>>[] = [];
  public selectedProviders$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public gameTypes: Partial<IListItem<string>>[] = [];
  public selectedGameType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public searchKeyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public showLoader!: boolean;

  constructor() {
    this.initialize();
  }

  public async initialize(): Promise<void> {
    this.allCasinoGames = await this.envAdapterService.getCasinoGames();
    this.categories = await this.envAdapterService.getCasinoCategories();

    if (this.categories[0]) {
      this.handleCasinoCategoryChange(this.categories[0].value);
    }

    this.observeChangesOnFilterParams();
  }

  private observeChangesOnFilterParams(): void {
    combineLatest([this.selectedCategory$, this.selectedProviders$, this.selectedGameType$, this.searchKeyword$])
      .pipe(debounceTime(500))
      .subscribe(([selectedCategory, selectedProviders, selectedGameType, searchKeyword]: [number | undefined, string[], string, string]) => {
        const filteredCasinoGames: Partial<ICasinoGameDetails<any, any>>[] = filterGamesByParams(
          this.allCasinoGames,
          selectedCategory,
          selectedProviders,
          selectedGameType,
          searchKeyword
        );

        this.filteredCasinoGames$.next(filteredCasinoGames);
        if (this.showLoader) {
          this.showLoader = false;
        }
      });
  }

  public handleCasinoCategoryChange(categoryId: number | undefined): void {
    this.showLoader = true;
    const tmpFilteredGames: Partial<ICasinoGameDetails<any, any>>[] = handleCategoryChange(this.allCasinoGames, categoryId);
    this.providers = transformVendorsToItemList(tmpFilteredGames);
    this.gameTypes = transformTypesToItemList(tmpFilteredGames);

    this.selectedCategory$.next(categoryId);
    this.selectedProviders$.next(this.providers?.map(item => item.value!));
    this.selectedGameType$.next(this.gameTypes[0].value || '');
    this.searchKeyword$.next('');
    // this.selectedCategory = categoryId;
    // this.filteredCasinoGames = handleCategoryChange(this.allCasinoGames, this.selectedCategory);
    // this.providers = transformVendorsToItemList(this.filteredCasinoGames);
    // this.gameTypes = [];
    // setTimeout(() => {
    //   this.gameTypes = transformTypesToItemList(this.filteredCasinoGames);
    // }, 100);
  }

  public handleProvidersChange(providers: string[]): void {
    //this.filteredCasinoGames = filterGamesByType(this.filteredCasinoGames, gameType);
    this.showLoader = true;
    this.selectedProviders$.next(providers);
    this.searchKeyword$.next('');
  }

  public handleGameTypeChange(gameType: string): void {
    //this.filteredCasinoGames = filterGamesByType(this.filteredCasinoGames, gameType);
    this.showLoader = true;
    this.selectedGameType$.next(gameType);
    this.searchKeyword$.next('');
  }

  public handleKeywordChange(event: Event): void {
    this.searchKeyword$.next((event as CustomEvent)?.detail?.value);
  }

  // public filterCasinoGamesByAllParams(): void {
  //   this.filteredCasinoGames = filterGamesByParams(this.allCasinoGames, this.selectedCategory, this.selectedProviders, this.selectedGameType, this.searchKeyword);
  // }
}
