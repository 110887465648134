import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'bp-angular-library';
import { IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { JackpotWinnerComponent } from './components/jackpot-winner/jackpot-winner.component';
import { EnvAdapterService } from 'src/app/core/env-specific/env-adapter.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JackpotsService {
  private authService: AuthService = inject(AuthService);
  private envAdapterService: EnvAdapterService = inject(EnvAdapterService);
  private modalController: ModalController = inject(ModalController);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  constructor() {
    this.initialize();
  }

  public initialize(): void {
    this.getJackpotsListForPlayers();
  }

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    if (playerId === this.authService.user$.value?.id) {
      this.presentJackpotWinnerModal(jackpot);
      this.envAdapterService.updateUserWithProfileData();
    }
  }

  public async getJackpotsListForPlayers(): Promise<void> {
    const tmpValue: IJackpot[] | null = await this.envAdapterService.getJackpotsListForPlayers();
    this.jackpots$.next(tmpValue);
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    const tmpJackpots: IJackpot[] = this.jackpots$.value || [];
    const index: number = tmpJackpots.findIndex(item => item.id === jackpot.id);

    if (index !== -1) {
      tmpJackpots[index] = { ...tmpJackpots[index], ...jackpot };
      this.jackpots$.next(tmpJackpots);
    }
  }

  private async presentJackpotWinnerModal(jackpotDetails: IJackpot): Promise<void> {
    const modal = await this.modalController.create({
      component: JackpotWinnerComponent,
      componentProps: {
        jackpotDetails
      },
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 10000);

    // const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   // console.log('SoftKeyboardComponent closed and confirmed');
    // }
  }
}
