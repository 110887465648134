import { Routes } from '@angular/router';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { MAIN_ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';

export const BETPLATFORM_DEV_JUST_CASINO_ROUTES: Routes = [
  //#region Main
  {
    path: MAIN_ROUTE_PATHS.casino,
    loadChildren: () => import('../../../../pages-casino/casino.routes').then(m => m.CASINO_ROUTES)
  },
  {
    path: MAIN_ROUTE_PATHS.greekKeno,
    loadComponent: () => import('../../../../pages-keno/keno/keno.component').then(m => m.kenoComponent)
  },
  //#endregion Main
  {
    path: MAIN_ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: '',
    redirectTo: MAIN_ROUTE_PATHS.casino, // Default page
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: MAIN_ROUTE_PATHS.notFound
  }
];

export const BETPLATFORM_DEV_JUST_CASINO_SIDEMENU: Partial<INavGroup>[] = [
  {
    path: MAIN_ROUTE_PATHS.casino,
    label: 'Casino',
    t9nKey: '',
    sortOrder: 3,
    icon: 'ph-club'
  },
  {
    path: MAIN_ROUTE_PATHS.greekKeno,
    label: 'Grčki kino',
    t9nKey: '',
    sortOrder: 4,
    icon: 'ph-fire-simple'
  }
];
