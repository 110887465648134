import { StorageService, AuthService, I18nService } from 'bp-angular-library';
import { Router, Routes } from '@angular/router';
import { EnvAdapterService } from '../env-specific/env-adapter.service';
import { SUPPORTED_LANGUAGE_OPTIONS } from 'src/app/shared/models/i18n/i18n.const';

export function appInitFactory(
  router: Router,
  storageService: StorageService,
  authService: AuthService,
  i18nService: I18nService,
  envAdapterService: EnvAdapterService
): () => Promise<boolean> {
  return () =>
    new Promise(async (resolve, reject) => {
      await storageService.initializeStorageService();
      await authService.handleAppInitialization();
      await i18nService.initializeLanguage(SUPPORTED_LANGUAGE_OPTIONS);

      const appRoutes: Routes = envAdapterService.buildAllRoutes();
      router.resetConfig(appRoutes);

      resolve(true);
    });
}
